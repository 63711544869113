import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';

import { EXTERNAL_URL, handleScrollToTop } from 'src/constants';

import Header from '../Header/Header';
import Footer from '../Landing/Footer/Footer';
import Seo from '../SEO/SEO';

import ReleaseNoteCard, { ReleaseNoteCardProps } from './ReleaseNoteCard';
import {
  ChangelogMainContainer,
  ReleaseTitleStyled,
  StyledLink,
  TimeDateContainer,
  TimeDateStyled,
  StyledChangelogWrapper,
  StyledWrapperContent,
  StyledTypography,
  StyledTextLink,
} from './styled';

export default function ChangelogPage() {
  const { t } = useTranslation();
  const releaseNotesData: ReleaseNoteCardProps[] = t('changelogPage.releaseNotes', { returnObjects: true });

  useEffect(() => {
    window.addEventListener('load', handleScrollToTop);

    return () => window.removeEventListener('load', handleScrollToTop);
  }, []);

  return (
    <StyledChangelogWrapper>
      <Seo title="Changelog" />

      <Header title={t('changelogPage.headerTitle')} />

      <ChangelogMainContainer>
        <TimeDateContainer flexDirection="column">
          <TimeDateStyled mb="10px"> {t('changelogPage.date')}</TimeDateStyled>

          <TimeDateStyled> {t('changelogPage.time')}</TimeDateStyled>
        </TimeDateContainer>

        <StyledWrapperContent>
          <Box>
            <ReleaseTitleStyled> {t('changelogPage.releaseTitle')}</ReleaseTitleStyled>

            <StyledTypography variant="h5">{t('changelogPage.releaseTitleText')}</StyledTypography>
          </Box>

          <Box>
            {releaseNotesData.map(({ title, features }, idx) => (
              <ReleaseNoteCard key={idx} title={title} features={features} />
            ))}
          </Box>

          <StyledLink href={EXTERNAL_URL.DASHBOARD}>
            <StyledTextLink>{t('changelogPage.linkToDashboard')}</StyledTextLink>

            <ArrowForward sx={{ marginLeft: '10px' }} />
          </StyledLink>
        </StyledWrapperContent>
      </ChangelogMainContainer>

      <Footer />
    </StyledChangelogWrapper>
  );
}
