import React from 'react';

import { Box, Typography } from '@mui/material';

import { ChangelogCardStyled, ChangelogLabelStyled, ChangelogMainTextStyled, StyledTypography } from './styled';

export interface ReleaseNoteCardProps {
  title: string;
  features: { featureLabel: string; releaseNoteText: string }[];
}

const ReleaseNoteCard = ({ title, features }: ReleaseNoteCardProps) => {
  return (
    <ChangelogMainTextStyled>
      <Box>
        <Typography variant="h3">{title}</Typography>
      </Box>

      {features.map(({ featureLabel, releaseNoteText }, idx) => (
        <ChangelogCardStyled key={idx}>
          <ChangelogLabelStyled display="flex">{featureLabel}</ChangelogLabelStyled>
          <StyledTypography variant="h5">{releaseNoteText}</StyledTypography>
        </ChangelogCardStyled>
      ))}
    </ChangelogMainTextStyled>
  );
};

export default ReleaseNoteCard;
