import { Box, Link, styled, Typography } from '@mui/material';

export const ChangelogMainContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1248px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 24px',
  margin: '100px auto 180px',
  flex: '1 0 auto',

  [theme.breakpoints.down('tabletM')]: {
    flexDirection: 'column',
    margin: '48px auto 200px',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '0 16px',
  },
}));

export const ReleaseTitleStyled = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '28px',
  paddingBottom: '15px',

  [theme.breakpoints.down('tabletM')]: {
    lineHeight: '32px',
    paddingBottom: '24px',
  },
}));

export const ChangelogCardStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '21px',

  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '28px',
  },
}));

export const ChangelogMainTextStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px',
  lineHeight: '28px',

  h3: {
    marginBottom: '20px',
  },

  [theme.breakpoints.down('tabletM')]: {
    h3: {
      lineHeight: '32px',
      marginTop: '32px',
    },
  },
}));

export const ChangelogLabelStyled = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(270deg, rgba(255, 180, 114, 0.2) 0%, rgba(242, 102, 81, 0.2) 100%)',
  borderRadius: '50px',
  width: '147px',
  minWidth: '147px',
  marginRight: '20px',
  height: '34px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '14px',

  [theme.breakpoints.down('tabletS')]: {
    margin: '0 0 14px',
  },
}));

export const TimeDateContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '105px',
  marginRight: '99px',

  [theme.breakpoints.down('laptop')]: {
    marginRight: '50px',
  },

  [theme.breakpoints.down('tabletM')]: {
    margin: '0 0 20px',
  },
}));

export const TimeDateStyled = styled(Typography)`
  max-width: 105px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme: { palette } }) => palette.text.primary};

  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  margin-top: 20px;
`;

export const StyledChangelogWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,

  h1: {
    fontSize: '56px',
    lineHeight: '64px',
  },

  [theme.breakpoints.down('mobile')]: {
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
}));

export const StyledWrapperContent = styled(Box)(({ theme }) => ({
  marginRight: '102px',
  maxWidth: '894px',
  width: '100%',

  [theme.breakpoints.down('desktop')]: {
    marginRight: 0,
  },

  [theme.breakpoints.down('tabletM')]: {
    marginRight: 0,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('tabletM')]: {
    lineHeight: '26px',
  },
}));

export const StyledReleaseNoteCard = styled(Box)(({ theme }) => ({
  marginBottom: '20px',

  [theme.breakpoints.down('tabletM')]: {
    marginBottom: '12px',
  },
}));

export const StyledTextLink = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.primary}`,
  display: 'inline-block',
  lineHeight: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
}));
